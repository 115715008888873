/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-259456431-3",
};

//Home Page
const greeting = {
  title: "Hello",
  title2: "Harsh",
  logo_name: "Harsh Panday",
  nickname: "Harsh",
  full_name: "Harsh Panday",
  subTitle:
    "I am an avid Data Science enthusiast, Full Stack Developer, a Python, Java and C# programmer.",
  resumeLink:
    "https://drive.google.com/file/d/1i_3yJK0KFcY4XFarnh1HELMPJ8gyJeF2/view?usp=sharing",
  mail: "mailto:pandayharsh22@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/Harshpanday",
  linkedin: "https://www.linkedin.com/in/harsh-panday/",
  gmail: "pandayharsh22@gmail.com",
  
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using ReactJS, VueJS.",
        "⚡ Creating application backend in Django, Node & Flask.",
        "⚡ Maintaining databases like PostgreSQL & MySQL.",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: "C#",
          fontAwesomeClassname: "logos:c-sharp",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: ".NET 6",
          fontAwesomeClassname: "mdi:dot-net",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "Vuejs",
          fontAwesomeClassname: "simple-icons:vuejs",
          style: {
            color: "#029FCE",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        // {
        //   skillName: "C",
        //   fontAwesomeClassname: "simple-icons:c",
        //   style: {
        //     color: "#E94E32",
        //   },
        // },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#E94E32",
          },
        },
        
        {
          skillName: "NextJS",
          fontAwesomeClassname: "simple-icons:nextdotjs",
          style: {
            color: "#FFFFFF",
            backgroundColor: "#1D1D1D",
            borderRadius: "50%",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#DE33A6",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#4479A1",
          },
        },

        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Experience with Data Analysis, Natural Language Processing and Computer Vision.",
        "⚡ Certifications by Google and Stanford University.",
        "⚡ Developed 10+ Projects like Google's Chaii, Closing price prediction and more.",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Pandas",
          fontAwesomeClassname: "simple-icons:pandas",
          style: {
            backgroundColor: "transparent",
            color: "#6E6E6E",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms like AWS and GCP.",
        "⚡ Proficiency with hosting, maintaining and managing websites.",
        "⚡ Deploying deep learning models on cloud.",
        "⚡ Mastery in Continuous Integration.",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "The Ohio State University",
      subtitle: "Masters in Computer Science and Engineering",
      logo_path: "osulogo.svg",
      alt_name: "OSU",
      duration: "2021 - 2023",
      descriptions: [
        "⚡ GPA: 3.9/4.0",
        "⚡ Studied Advanced Software Development Concepts, Machine Learning & Data Visualization.",
        "⚡ Worked as a Graduate Research Associate in The Computational Epidemiology Lab.",

      ],
      website_link: "https://www.osu.edu/",
    },
    {
      title: "Vellore Institute of Technology",
      subtitle: "B.Tech. in Computer Science",
      logo_path: "vitlogo.jpeg",
      alt_name: "VIT",
      duration: "2017 - 2021",
      descriptions: [
        "⚡ I have studied core subjects like Data Structures, Object Oriented Programming, DBMS, Networking.",
        "⚡ I have also studied courses for Machine Learning, Web Development, Parallel Programming.",
        "⚡ I have implemented several projects using the concepts and technologies I learned in my Computer Science courses.",
      ],
      website_link: "https://vit.ac.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Machine Learning",
      subtitle: "Stanford",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/KGSTF647S7ZE",
      alt_name: "Stanford",
      color_code: "#0C9D5899",
    },  
    {
      title: "How Google does Machine Learning",
      subtitle: "Google",
      logo_path: "google_logo.png",
      certificate_link:
        "https://coursera.org/share/63142a204ff117449828a6e1ab0091bb",
      alt_name: "google",
      color_code: "#FAE16F",
    },
    {
      title: "Database Management Essentials",
      subtitle: "University of Colorado Boulder",
      logo_path: "ucb.webp",
      certificate_link: "https://coursera.org/share/66d97fe95b698e8c39f8c173fc56f1bc",
      alt_name: "University of Colorado Boulder",
      color_code: "#C5E2EE",
    },
    {
      title: "Launching into Machine Learning by Google Cloud",
      subtitle: "Google",
      logo_path: "google_logo.png",
      certificate_link:
        "https://coursera.org/share/a23215a7214222b9f7251751bf415448",
      alt_name: "google",
      color_code: "#FAE16F",
    },
    {
      title: "Python Data Structures",
      subtitle: "University of Michigan",
      logo_path: "ucm.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/FUA643HUVM4X",
      alt_name: "university of michigan",
      color_code: "#F6B808",
    },
    {
      title: "Using Python to Access Web Data",
      subtitle: "University of Michigan",
      logo_path: "ucm.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/ZG7N5JJRAY3Z",
      alt_name: "university of michigan",
      color_code: "#F6B808",
    },
    {
      title: "Using Databases with Python",
      subtitle: "University of Michigan",
      logo_path: "ucm.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/WXNNXTRD23M9",
      alt_name: "university of michigan",
      color_code: "#F6B808",
    },
    
    {
      title: "Data Communications and Network Services",
      subtitle: "CISCO",
      logo_path: "cisco.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/CF6G8G8RMHWX",
      alt_name: "cisco",
      color_code: "#B5E327",
    },
    {
      title: "Server-side Development with NodeJS, Express and MongoDB",
      subtitle: "The Hong Kong University of Science and Technology",
      logo_path: "hong_kong.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/BPDWSL23FL7F",
      alt_name: "The Hong Kong University of Science and Technology",
      color_code: "#EE4242",
    },
    {
      title: "Cybersecurity and the Internet of Things",
      subtitle: "University System of Georgia",
      logo_path: "usg.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/3FA9UA93VNER",
      alt_name: "University System of Georgia",
      color_code: "#63A8FF",
    },
    
    
    {
      title: "Data Warehouse Concepts, Design, and Data Integration",
      subtitle: "University of Colorado Boulder",
      logo_path: "ucb.webp",
      certificate_link: "https://coursera.org/share/7827392b76f970d0e3d7df05b25d6b5e",
      alt_name: "University of Colorado Boulder",
      color_code: "#C5E2EE",
    },
    {
      title: "Relational Database Support for Data Warehouses",
      subtitle: "University of Colorado Boulder",
      logo_path: "ucb.webp",
      certificate_link: "https://coursera.org/share/6f767616f2d4cbc291773ed860fd065e",
      alt_name: "University of Colorado Boulder",
      color_code: "#C5E2EE",
    },
    {
      title: "Business Intelligence Concepts, Tools, and Applications ",
      subtitle: "University of Colorado Boulder",
      logo_path: "ucb.webp",
      certificate_link: "https://coursera.org/share/3b7fdcdf62f3fd8398f85dcdb1dc164e",
      alt_name: "University of Colorado Boulder",
      color_code: "#C5E2EE",
    },
    {
      title: "Mathematics for Machine Learning: Linear Algebra",
      subtitle: "Imperial College London",
      logo_path: "icl.png",
      certificate_link: "https://coursera.org/share/1b12d620b76e27c58e0325e8af71872d",
      alt_name: "Imperial College London",
      color_code: "#C5E2EE",
    },
    {
      title: "Fundamentals of Digital Image and Video Processing",
      subtitle: "Northwestern University",
      logo_path: "northwestern.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/GK2TKDEHNMUL",
      alt_name: "Northwestern University",
      color_code: "#2AAFED",
    },
    
    
  ],
};
// {
//   title: "Vellore Institute of Technology",
//   subtitle: "B.Tech. in Computer Science",
//   logo_path: "vitlogo.png",
//   alt_name: "VIT",
//   duration: "2017 - 2021",
//   descriptions: [
//     "⚡ I have studied core subjects like Data Structures, Object Oriented Programming, DBMS, Networking.",
//     "⚡ I have also completed various online courses for Machine Learning, Web Development, Parallel Programming.",
//     "⚡ I have implemented several projects based on what I've learnt under my Computer Engineering course. ",
//   ],
//   website_link: "https://vit.ac.in/",
// }

// Experience Page
const experience = {
  title: "Experience",
  //subtitle: "Work, Internship and Volunteership",
  description:
    "As a software developer I have had the opportunity to put my coding knowledge to work, develop applications from scratch, maintain strong attention to detail, produce simple and reusable code and engage with the end users to integrate their requirements into a product.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Graduate Research Associate ",
          company: "Computational Epidemiology Lab",
          company_url: "https://u.osu.edu/hyder.22/students/",
          logo_path: "osulogo.svg",
          duration: "October 2021 - Present",
          location: "Columbus, Ohio",
          description: [ 
            "⚡ Directed the creation of a user-inspired web-based application using Python, JavaScript, and PostgreSQL, oversaw architecture, design, and development phases to ensure successful delivery",
            "⚡ Implemented and maintained REST API routes using Django-REST-framework.",
            "⚡ Incorporated MVCC to optimize PostgreSQL's ability to manage data, resulting in improved performance and data consistency.",
            "⚡ Refactored and optimized VueJS frontend to enhance performance and maintainability of the website.",
            "⚡ Organized, cleaned, analyzed data, and developed an ETL data pipeline to feed data into an informational infrastructure of a smart regional foodshed using Python.",
            "⚡ Automated the ETL data pipeline saving hours of manual work of ingesting data into the informational infrastructure."
          ],
          color: "#2962FF",
        },
        {
          title: "Data Management and UI Development Intern",
          company: "IC FOODS",
          company_url: "https://www.ic-foods.org/",
          logo_path: "icfoods.png",
          duration: "May 2022 - August 2022",
          location: "Davis, California",
          description: [ 
            "⚡ Spearheaded the development of an interactive user interface using JavaScript for ICICLE AI Institute's knowledge graphs, enhancing the accessibility and usability of the institute’s data.",
            "⚡ Supported data curation efforts for projects such as IVUEFOODS within IC-FOODS, contributing to the success of data analysis and research efforts.",
            "⚡ Developed an interactive user interface using JavaScript resulting in cost savings of over $3000 annually in server expenses.",
          ],
          color: "#2962FF",
        },
        {
          title: "Machine Learning Intern",
          company: "Linux World Informatics",
          company_url: " ",
          logo_path: "linuxworld.jpeg",
          duration: "May 2020 - July 2020",
          location: "Jaipur, Rajasthan",
          description: [ 
            "⚡ Developed a real-time face recognition system using Python and OpenCV, achieving 90% accuracy in recognizing individuals.",
            "⚡ Proficiently deployed the application using Docker and Kubernetes container orchestration tools.",
            "⚡ Proactively sought out opportunities to improve development practices and promote code review and test-driven development.",
          ],
          color: "#2962FF",
        },
        {
          title: "Full Stack Developer",
          company: "Wisel",
          company_url: " ",
          logo_path: "wisel.jpeg",
          duration: "April 2020 - May 2020",
          location: "Jaipur, Rajasthan",
          description: [ 
            "⚡ Improved user experience for a local business by developing a website using HTML5, CSS3, and modern JavaScript frameworks.",
            "⚡ Implemented various features and functionalities to enhance the website's performance, resulting in increased user engagement and satisfaction.",
            "⚡ Programmed object-oriented code in Django with 100% accordance with the industry and company standards.",
            "⚡ Strengthened my client engagement and product development skills to consistently exceed client expectations.",
          ],
          color: "#2962FF",
        },
      ],
    },
   
   
   
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Communications Specialist",
          company: "Becoming I Foundation",
          company_url: "http://www.becomingifoundation.org/",
          logo_path: "BIF.jpeg",
          duration: "December 2018 - December 2019",
          location: " ",
          description:[
            "⚡ Taught kids at St. Judes or a local public school every week.",
            "⚡ Organized various events like food drives, street cleaning rallies, and more."
          ],
          color: "#4285F4",
        },
        
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "I really enjoy learning new technologies and I think the best way to learn a new technology is by developing a project using it. You can checkout some of my projects below. Some of these projects are research projects thus I am unable to share the code base with you.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: " ",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as soon as possible. ",
  }
};

const projects = {
  data: [
    {
      name: "Store Closure",
      url: " ",
      description:
        "Predicted and visualized the varied impacts of a supermarket closure on the local population it serves using an Agent Based model.",
      languages: [
        {
          name: "Django",
          iconifyClass: "vscode-icons:file-type-django",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Vuejs",
          iconifyClass: "logos-vue",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "PostgreSQL",
          iconifyClass: "logos-postgresql",
        },
        {
          name: "Docker",
          iconifyClass: "skill-icons:docker",
        },
      ],
    },
    {
      name: "Flappy Bird ",
      url: "https://github.com/Harshpanday/FlappyBird",
      description:
        "I recreated the renowned game, Flappy Bird, using the C# and Unity Engine.Includes an interactive start menu, game over window, persistent storage for high scores.",
      languages: [
        {
          name: "C#",
          iconifyClass: "logos:c-sharp",
        },
        {
          name: "Unity Engine",
          iconifyClass: "bi:unity",
        },
      ],
    },
    {
      name: "Breakfast API",
      url: "https://github.com/Harshpanday/Breakfast_API",
      description:
        "Designed an industry-level REST API for a restaurant application.Engineered it to facilitate CRUD operations.",
      languages: [
        {
          name: "C#",
          iconifyClass: "logos:c-sharp",
        },
        {
          name: "ASP.NET",
          iconifyClass: "mdi:dot-net",
        },
      ],
    },
    {
      name: "IVUEFOODS ",
      url: " ",
      description:
        "A data agnostic reusable interface to create interactive data visualizations.",
      languages: [
        {
          name: "Vuejs",
          iconifyClass: "logos-vue",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "D3",
          iconifyClass: "logos-d3",
        },
      ],
    },
    {
      name: "Google’s Chaii ",
      url: " ",
      description: "A Natural Language Processing model which understood the given context and answered the asked question correctly in the respective language.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "TensFlow",
          iconifyClass: "logos-tensorflow",
        },
        {
          name: "Pandas",
          iconifyClass: "simple-icons:pandas",
        }
      ],
    },
    {
      name: "Smart Foodsheds",
      url: " ",
      description:
        "Developed an informational infrastructure for foodsheds and an ETL data pipeline to feed data into an informational infrastructure.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Pandas",
          iconifyClass: "simple-icons:pandas",
        },
        {
          name: "Vuejs",
          iconifyClass: "logos-vue",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "D3",
          iconifyClass: "logos-d3",
        },
      ],
    },
    {
      name: "Closing Price Prediction ",
      url: " ",
      description: "Designed a hybrid algorithm using Long Short-Term Memory (LSTM) neural network and Sentiment Analysis to predict the next day closing price of a stock.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "TensFlow",
          iconifyClass: "logos-tensorflow",
        },
        {
          name: "Pandas",
          iconifyClass: "simple-icons:pandas",
        }
      ],
    },
    {
      name: "Website for Smart foodsheds",
      url: " ",
      description:
        "This website allows user to create interactive knowledge graphs using the data from Smart Foodshed's informational infrastructure.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Vuejs",
          iconifyClass: "logos-vue",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Pandas",
          iconifyClass: "simple-icons:pandas",
        }
      ],
    },
    {
      name: "Movie Hub",
      url: "https://github.com/Harshpanday/MovieHub",
      description:
        "A react website which allows users to search for movies on IMDB using OMDb api.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "Online Crime Reporting System",
      url: "https://github.com/Harshpanday/OCRS2",
      description:
        "Pioneered an online platform which allows user to file police complaints without any hassle.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Nodejs",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "NoSQL",
          iconifyClass: "vscode-icons:file-type-sql",
        },
        {
          name: "Php",
          iconifyClass: "logos-php",
        },
      ],
    },
    {
      name: "Customer Segmentation",
      url: " ",
      description:
        "A machine learning model which can determine which customers are likely to buy a product using unsupervised learning.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Pandas",
          iconifyClass: "simple-icons:pandas",
        }
      ],
    },
    {
      name: "Contact App",
      url: "https://github.com/Harshpanday/Contact_app",
      description:
        "A react application which allows users to create and store contacts in their local indexed database.",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    // {
    //   name: "Time Series Analysis",
    //   url: " ",
    //   description:
    //     "Programmed Generative Adversarial Networks (GAN) and Wasserstein Generative Adversarial Networks (WGAN) to perform time series analysis.",
    //   languages: [
    //     {
    //       name: "Python",
    //       iconifyClass: "logos-python",
    //     },
    //     {
    //       name: "Pandas",
    //       iconifyClass: "simple-icons:pandas",
    //     }
        
    //   ],
    // },
    // {
    //   name: "Target Detection Algorithm",
    //   url: " ",
    //   description:
    //     "Developed an algorithm using canny edge detection that can detect a target in an image regardless of the noise and color of the image.",
    //   languages: [
    //     {
    //       name: "Matlab",
    //       iconifyClass: "vscode-icons:file-type-matlab",
    //     },
        
    //   ],
    // },
    
    
    // {
    //   name: "Game of Life",
    //   url: "https://github.com/Harshpanday/Cellular-Automata",
    //   description:
    //     "Simulating John Conway's Game of Life",
    //   languages: [
    //     {
    //       name: "Python",
    //       iconifyClass: "logos-python",
    //     }
    //   ],
    // },
    
    
    
    
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
