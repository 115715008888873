import React from "react";
import Certifications from "../../containers/certification/Certifications";
import './CertificationComponent.css'
import Header from "../../components/header/Header";
import CertificationImg from "./CertificationImg";
import { Fade } from "react-reveal";

const Certification = (props) =>{
    console.log(props);
    const theme = props.theme;
    return(
        <div className="certification-main">
            <Header theme={props.theme} setTheme={props.setTheme} />
            <div className="basic-education">
            <Fade bottom duration={2000} distance="40px">
          <div className="heading-div">
            <div className="heading-img-div">
              <CertificationImg theme={theme} />
            </div>
            <div className="heading-text-div">
              <h1 className="heading-text" style={{ color: theme.text }}>
                Certificates
              </h1>
              <p
                className="experience-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                Throughout my years as a software developer, I have completed several accredited courses. Check out some of them below.
              </p>
            </div>
          </div>
        </Fade>
            <Certifications theme={props.theme} />
            </div>
        </div>
    )
}
export default Certification;