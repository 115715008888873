import React from "react";

export default function ContactImg(props) {
  const theme = props.theme;
  return (
    <svg 
    xmlns="http://www.w3.org/2000/svg" 
    data-name="Layer 1" 
    width="751.13137" 
    height="569.6024" 
    viewBox="0 0 751.13137 569.6024"
    //xmlns:xlink="http://www.w3.org/1999/xlink"
    >
    <path d="M352.382,616.54172,302.64,599.16277a170.00557,170.00557,0,0,1,3.57733-81.71955c18.87025,45.43,77.72551,
    58.13147,110.40409,94.90211a102.2593,102.2593,0,0,1,23.696,85.40922l9.52448,36.13587a171.3539,171.3539,0,0,
    1-122.60393-74.52059,165.52018,165.52018,0,0,1-18.32333-37.26211C330.38646,620.58479,352.382,616.54172,352.382,
    616.54172Z" transform="translate(-224.43432 -165.1988)" fill="#f2f2f2"/>
    <path d="M690.56,204.28883v397.23l-1.38,
    16.54a126.31089,126.31089,0,0,1-34.47,76.58q-4.23,4.425-8.85,8.38a123.19691,123.19691,0,0,1-11.94,9.09c-2.38,
    1.6-4.82,3.11-7.3,4.54a125.99264,125.99264,0,0,1-46.87,15.85H461.39a38.92832,38.92832,0,0,1-25.62-9.59,39.50873,
    39.50873,0,0,1-8.7-10.8,38.896,38.896,0,0,1-4.76-18.69v-489.13a39.13978,39.13978,0,0,1,39.09-39.09H651.48c.45,0
    ,.89.01,1.34.02.29.01.58.02.87.05.39.01.78.04,1.16.08.49.03.97.07995,1.45.13995.44.06.88.12,1.32.19.09.01.17.03.26.04.56.09,
    1.12.19,1.67.31.55.11,1.11.24,1.65.38.39.1.79.21,1.18.32.01,0,.03.01.04.01,1.15.34,2.29.73,3.41,
    1.18005.35.12995.7.28,1.04.43l.15.06c.28.12.55.24.82.37.81.37,1.61.78,2.4,1.21a40.16218,40.16218,0,0,1,3.77,
    2.36005c.39.26995.77.56,1.14.85.31.23.62.47.92.73a.30368.30368,0,0,1,.07.05c.04.03.08.07.12.1.27.21.53.43.78.65.49.42.97.86,
    1.44,1.31.13.12.26.24.39.37.13.12.25.25.38.38.13.12.25.25.37.37a1.89271,1.89271,0,0,1,.2.2c.36.38.71.77,1.05,1.16.43.49.85.99,
    1.24,1.5.34.42.66.84.97,1.27.29.38995.56.78.83,1.18a3.281,3.281,0,0,1,.2.31c.28.41.54.83.8,1.25.65,1.06,1.24,2.16,1.78,
    3.27.2.42.39.83.57,1.25.23.51.44,1.02.64,1.53.08.19.15.39.22.58.11.29.21.58.31.87.11.32.22.65.32.97l.24.78c.14.47.26.93.38,
    1.4.07.28.14.56.2.85a39.06257,39.06257,0,0,1,.91,6.21c.01.26.03.51.04.77C690.55,203.09883,690.56,203.68879,690.56,204.28883Z"
     transform="translate(-224.43432 -165.1988)" fill="#3f3d56"/>
     <path d="M419.59818,343.16717a4.404,4.404,0,0,1-4.39886-4.39886V308.856a4.39887,4.39887,0,1,1,8.79773,0v29.91228A4.404,
     4.404,0,0,1,419.59818,343.16717Z" transform="translate(-224.43432 -165.1988)" fill="#3f3d56"/>
     <path d="M418.71841,285.10216a4.404,4.404,0,0,1-4.39886-4.39887V250.791a4.39887,4.39887,0,0,1,8.79773,0v29.91228A4.40405,
     4.40405,0,0,1,418.71841,285.10216Z" transform="translate(-224.43432 -165.1988)" fill="#3f3d56"/>
     <path d="M526.72821,179.73167a4.404,4.404,0,0,1,4.39887-4.39886h29.91228a4.39886,4.39886,0,0,1,0,8.79773H531.12708A4.40405,4.40405,0,0,1,
     526.72821,179.73167Z" transform="translate(-224.43432 -165.1988)" fill="#fff"/>
     <path d="M419.59818,394.194a4.404,4.404,0,0,1-4.39886-4.39886V359.88286a4.39887,4.39887,0,1,1,8.79773,0v29.91228A4.404,
     4.404,0,0,1,419.59818,394.194Z" transform="translate(-224.43432 -165.1988)" fill="#3f3d56"/>
     <path d="M693.59818,329.194a4.404,4.404,0,0,1-4.39886-4.39886V294.88286a4.39887,4.39887,0,1,1,8.79773,0v29.91228A4.404,
     4.404,0,0,1,693.59818,329.194Z" transform="translate(-224.43432 -165.1988)" fill="#3f3d56"/>
     <circle cx="353.32073" cy="14.53287" r="4.39886" fill="#fff"/>
     <path d="M671.10344,218.40126H441.78008a1.0156,1.0156,0,0,1,0-2.0307H671.10344a1.0156,1.0156,0,0,1,0,2.0307Z" 
     transform="translate(-224.43432 -165.1988)" fill="#fff"/><ellipse cx="234.72596" cy="39.90456" rx="5.95043" ry="6.08304" 
     fill="#fff"/><ellipse cx="255.28198" cy="39.90456" rx="5.95043" ry="6.08304" fill="#fff"/>
     <ellipse cx="275.838" cy="39.90456" rx="5.95043" ry="6.08304" fill="#fff"/>
     <path d="M658.727,200.52807H644.12505a1.10585,1.10585,0,0,0,0,2.21126H658.727a1.10585,1.10585,0,0,0,0-2.21126Z" 
     transform="translate(-224.43432 -165.1988)" fill="#fff"/>
     <path d="M658.727,204.67826H644.12505a1.10585,1.10585,0,0,0,0,2.21126H658.727a1.10585,1.10585,0,0,0,0-2.21126Z" 
     transform="translate(-224.43432 -165.1988)" fill="#fff"/><path d="M658.727,208.823H644.12505a1.10585,1.10585,0,0,0,
     0,2.21126H658.727a1.10585,1.10585,0,0,0,0-2.21126Z" transform="translate(-224.43432 -165.1988)" fill="#fff"/>
     <path d="M496.40426,373.589a9.45321,9.45321,0,0,0,1.22177,4.02833,13.63469,13.63469,0,0,0,6.77661,5.62043c3.42779,
     1.40269,7.24887,1.4459,10.88666,1.13314,3.37879-.2905,6.72319-.96386,10.11443-1.10264a70.469,70.469,0,0,1,
     7.86347.18756c5.07461.36018,10.12814,1.02847,15.14912,1.83864q2.03973.32508,4.07086.69856a2.013,2.013,0,0,
     1,.37807.07024c.02458.01007.06412.00221.08663.01637.04087.02574-.16534-.06526-.04034.038a3.75176,3.75176,0,0,
     1,.46359.5966l1.85437,2.38639a11.28035,11.28035,0,0,0,1.71258,2.05522,3.10092,3.10092,0,0,0,3.43272.23924,
     3.43044,3.43044,0,0,0,1.27652-1.45,12.51377,12.51377,0,0,1,1.518-2.19105c2.20615-2.57366,5.27228-4.15666,
     7.83679-6.32214a13.9584,13.9584,0,0,0,3.13426-3.58276,10.13153,10.13153,0,0,0,1.295-4.02323,13.45734,13.45734,0,0,
     0-2.06143-8.26146,16.50319,16.50319,0,0,0-6.69955-6.13212,28.92062,28.92062,0,0,0-9.98071-2.73525A90.43233,90.43233,
     0,0,0,545.668,356.332q-6.183.06882-12.34374.5438-6.169.47749-12.28945,1.35745a107.23267,107.23267,0,0,0-11.71067,
     2.14563,20.21421,20.21421,0,0,0-9.28987,4.74714A11.25418,11.25418,0,0,0,496.40426,373.589Z" 
     transform="translate(-224.43432 -165.1988)" fill="#e6e6e6"/>
     <path d="M553.57586,363.97756H514.558a2.92087,2.92087,0,0,0-2.91653,2.91651v.47033a2.92089,2.92089,0,0,0,
     2.91653,2.91654h39.01791a2.92089,2.92089,0,0,0,2.91653-2.91654v-.47033A2.92087,2.92087,0,0,0,553.57586,363.97756Z" 
     transform="translate(-224.43432 -165.1988)" fill="#fff"/>
     <path d="M553.57586,371.978H514.558a2.92088,2.92088,0,0,0-2.91653,2.91652v.47033a2.92089,2.92089,0,0,0,2.91653,
     2.91653h39.01791a2.92089,2.92089,0,0,0,2.91653-2.91653v-.47033A2.92088,2.92088,0,0,0,553.57586,371.978Z" 
     transform="translate(-224.43432 -165.1988)" fill="#fff"/>
     <path d="M634.58787,315.589a9.45335,9.45335,0,0,1-1.22177,4.02833,13.63474,13.63474,0,0,1-6.77661,5.62043c-3.4278,
     1.40269-7.24887,1.4459-10.88667,1.13314-3.37878-.2905-6.72319-.96386-10.11442-1.10264a70.469,70.469,0,0,
     0-7.86347.18756c-5.07462.36018-10.12814,1.02847-15.14912,1.83864q-2.03973.32508-4.07086.69856a2.01283,2.01283,0,0,
     0-.37807.07024c-.02459.01007-.06412.00221-.08663.01637-.04087.02574.16534-.06526.04034.038a3.75124,3.75124,0,0,
     0-.4636.5966l-1.85436,2.38639a11.28075,11.28075,0,0,1-1.71258,2.05522,3.10094,3.10094,0,0,1-3.43273.23924,3.43042,
     3.43042,0,0,1-1.27651-1.45,12.51382,12.51382,0,0,0-1.518-2.19105c-2.20614-2.57366-5.27227-4.15666-7.83678-6.32214a13.95821,
     13.95821,0,0,1-3.13426-3.58276,10.13155,10.13155,0,0,1-1.295-4.02323,13.45735,13.45735,0,0,1,2.06144-8.26146,16.50319,
     16.50319,0,0,1,6.69955-6.13212,28.92056,28.92056,0,0,1,9.9807-2.73525,90.43246,90.43246,0,0,1,11.02562-.36509q6.183.06882,
     12.34375.5438,6.169.47749,12.28945,1.35745a107.23267,107.23267,0,0,1,11.71067,2.14563,20.21413,20.21413,0,0,1,9.28986,
     4.74714A11.25415,11.25415,0,0,1,634.58787,315.589Z" transform="translate(-224.43432 -165.1988)" fill="#e6e6e6"/>
     <path d="M574.49973,308.89407v.47033a2.92089,2.92089,0,0,0,2.91654,2.91654h39.01791a2.92089,2.92089,0,0,0,
     2.91653-2.91654v-.47033a2.92087,2.92087,0,0,0-2.91653-2.91651H577.41627A2.92087,2.92087,0,0,0,574.49973,308.89407Z" 
     transform="translate(-224.43432 -165.1988)" fill="#fff"/><path d="M574.49973,316.89452v.47033a2.92089,2.92089,0,0,
     0,2.91654,2.91653h39.01791a2.92089,2.92089,0,0,0,2.91653-2.91653v-.47033a2.92088,2.92088,0,0,
     0-2.91653-2.91652H577.41627A2.92088,2.92088,0,0,0,574.49973,316.89452Z" transform="translate(-224.43432 -165.1988)" 
     fill="#fff"/><polygon points="599.58 555.014 587.19 555.013 581.296 507.224 599.582 507.225 599.58 555.014" fill={theme.skinColor}/>
     <path d="M823.68428,716.1637l-13.22-5.37-.39-.16-7.3,5.53a15.54249,15.54249,0,0,
     0-15.53,14.87c-.02.22-.02.45-.02.68v.51h39.95v-16.06Z" transform="translate(-224.43432 -165.1988)" fill="#2f2e41"/>
     <polygon points="570.564 543.484 558.877 547.596 537.455 504.472 554.705 498.403 570.564 543.484" fill={theme.skinColor}/>
     <path d="M793.34359,704.97268l-14.2529-.67773-.421-.02152-5.05069,7.63948a15.54246,15.54246,0,0,0-9.71415,
     19.1816c.05412.21414.13048.43115.20681.64809l.16928.4811,37.68529-13.2598-5.33047-15.14958Z" 
     transform="translate(-224.43432 -165.1988)" fill="#2f2e41"/><path d="M828.39282,514.15453c11.33506-.43955-2.7063,
     89.88978-2.7063,89.88978s4.10733,79.30546,3.2367,83.46726-.10978,15.38635-.10978,
     15.38635c-2.22075-4.04192-25.13224-.99139-25.13224-.99139l-3.11682-80.37587L784.651,557.13113l-22.06224,43.168,
     21.14563,66.26061s8.56336,7.92394,7.65278,11.0553-23.34095,10.19318-27.46279,
     10.353-2.26071-5.07241-2.46051-10.22468-19.59569-52.90458-26.41781-69.1522-1.51846-39.15745,1.964-55.80468,21.80339-47.8886,
     21.80339-47.8886C781.555,479.24781,817.05775,514.59409,828.39282,514.15453Z" transform="translate(-224.43432 -165.1988)" 
     fill="#2f2e41"/>
     <path d="M822.50582,528.63523c-28.10986.001-43.60058-5.626-51.82666-10.65039-10.05859-6.14356-11.77978-12.62207-11.84814-12.89551l-.02-.07812.979-12.32032.42823-.959a19.50468,19.50468,0,0,0,
     .73437-14.00684l-.03076-.09472.00781-.09864,6.57275-85.24463,23.25977-7.61914,6.7666-6.79882,30.12744-.437,4.19776,
     8.51026,23.751,8.9414-11.66845,69.45655-5.59375,9.6416,2.251,10.50293-.01562.09473-7.33838,43.7998-.40381.01758Q827.40719,
     528.64012,822.50582,528.63523Z" transform="translate(-224.43432 -165.1988)" fill="#e6e6e6"/><path d="M834.22375,
     348.75754a25.10467,25.10467,0,0,1-49.75065-6.77109l.0485-.35635a25.10467,25.10467,0,0,1,49.69445,7.12639Z" 
     transform="translate(-224.43432 -165.1988)" fill={theme.skinColor}/>
     <path d="M827.83428,361.68828c1.28922-2.61882,2.61359-5.43044,2.25129-8.32683s-3.20277-5.66485-5.99193-4.80406c-1.66786.51473-2.92844,
     2.16171-4.67189,2.246-2.39885.116-3.77423-2.62948-4.5209-4.91211l-3.041-9.29657a25.15359,25.15359,
     0,0,1-20.89111,4.72079c-2.82348-.64554-5.67593-1.90417-7.33217-4.28021s-1.64627-6.0617.62541-7.85842c1.11386-.881,
     2.59641-1.2157,3.66087-2.15577a4.10217,4.10217,0,0,0-3.27565-7.13851l7.65693-.957-2.28138-4.12614a7.535,7.535,0,0,
     0,5.99172,1.0992c2.06-.37093,4.0002-1.21914,6.01549-1.78479a23.1519,23.1519,0,0,1,25.75442,10.81464,9.80174,9.80174,
     0,0,1,10.687,3.08588c2.04974,2.60156,2.60717,6.07331,2.68659,9.38439a40.7145,40.7145,0,0,1-4.15033,18.86012,15.451,15.451,
     0,0,1-3.48657,4.94577,6.97822,6.97822,0,0,1-5.60143,1.83184" transform="translate(-224.43432 -165.1988)" fill="#2f2e41"/>
     <path d="M543.97657,586.99171c-.1182,0-.2364-.00233-.35577-.007a8.64868,8.64868,0,0,1-6.95472-3.95541,97.7041,97.7041,0,
     0,0-20.56836-22.28025c-4.82708-3.85817-9.87135-7.44558-14.74908-10.91537-12.58418-8.94786-24.43527-17.37515-31.01384-29.01953-14.34323-25.39137,
     2.54805-60.75772,26.67878-76.61837,27.066-17.78857,62.667-20.57884,97.107-20.94451q5.36889-.05677,10.74418-.05649h0a1024.40447,
     1024.40447,0,0,1,185.0052,16.91632c20.29352,3.74405,41.53558,8.33064,58.60562,19.46145,16.96872,11.065,27.2826,28.48967,
     26.27642,44.39282-1.46908,23.216-26.07961,40.56318-48.48971,45.09854C804.902,553.38325,782.712,550.46429,
     759.22,547.373c-5.18634-.68184-10.54854-1.38756-15.85133-2.0007C685.73837,538.711,577.47614,
     559.24271,572.896,560.1202l-4.54409.86992-17.63135,22.689A8.46124,8.46124,0,0,1,543.97657,586.99171Z" 
     transform="translate(-224.43432 -165.1988)" fill={theme.accentColor}/>
     <path d="M800.61072,473.7361H557.02708a5.29984,5.29984,0,0,1,0-10.59968H800.61072a5.29984,5.29984,0,0,1,0,10.59968Z" 
     transform="translate(-224.43432 -165.1988)" fill="#fff"/>
     <path d="M800.61072,506.26717H557.02708a5.29985,5.29985,0,1,1-.01106-10.59969h243.5947a5.29985,5.29985,0,0,1,0,10.59969Z" 
     transform="translate(-224.43432 -165.1988)" fill="#fff"/>
     <path d="M585.41314,538.79747H557.02708a5.29985,5.29985,0,0,1,0-10.59969h28.38606a5.29985,5.29985,0,0,1,0,10.59969Z"
      transform="translate(-224.43432 -165.1988)" fill="#fff"/>
      <path d="M820.65563,557.11683a10.75474,10.75474,0,0,0,5.2863-15.62085l26.51276-94.29815-23.26162-2.43073-18.74668,
      93.45633a10.813,10.813,0,0,0,10.20924,18.8934Z" transform="translate(-224.43432 -165.1988)" 
      fill={theme.skinColor}/>
      <path d="M856.37687,395.4334s5.67782.204,2.87127,11.9096c-2.24685,9.37123-10.4984,68.15869-16.95,
      79.88015a8.44878,8.44878,0,0,1,1.83129,10.1948l-25.7079-10.60321c-2.13511-5.92785-.48174-11.30978,
      1.94665-11.78633l3.0035-17.14452,4.42638-57.5153,24.47721-5.15551Z" transform="translate(-224.43432 -165.1988)"
       fill="#e6e6e6"/>
       <path d="M716.70766,549.46123a10.75473,10.75473,0,0,1,3.75639-16.05757l27.26156-94.0844,
       21.03954,10.21515-33.413,89.26979a10.813,10.813,0,0,1-18.64449,10.657Z" transform="translate(-224.43432 -165.1988)" 
       fill={theme.skinColor}/><path d="M771.72045,393.28463s-4.92992-2.824-8.72558,8.59924c-3.03871,9.14519-27.064,63.43-27.77221,76.79091a8.44878,8.44878,0,0,0-6.93708,7.69185l27.43137,4.56555c4.94264-3.9075,6.37948-9.35123,4.56859-11.03789l6.49953-16.14658,26.60245-51.18508-18.06724-17.29994Z" transform="translate(-224.43432 -165.1988)" fill="#e6e6e6"/>
       <path d="M974.375,734.49387l-748.75.30733a1.19069,1.19069,0,0,1,0-2.38137l748.75-.30733a1.19069,1.19069,0,0,1,0,2.38137Z" transform="translate(-224.43432 -165.1988)" fill="#cacaca"/>
       <path d="M546.32272,681.6494a38.71289,38.71289,0,1,1,38.71289-38.71289A38.75664,38.75664,0,0,1,546.32272,681.6494Zm0-75.42578a36.71289,36.71289,0,1,0,36.71289,36.71289A36.75466,36.75466,0,0,0,546.32272,606.22362Z" transform="translate(-224.43432 -165.1988)" fill="#fff"/>
       <path d="M543.49926,658.00905c-.0804,0-.1608-.00262-.2412-.00654a4.93171,4.93171,0,0,1-3.65585-1.8995l-6.30247-8.10261a4.93373,4.93373,0,0,1,.86478-6.92474l.22681-.17649a4.93325,4.93325,0,0,1,6.926.86543,3.59877,3.59877,0,0,0,5.45011.26538L559.564,628.5204a4.94113,4.94113,0,0,1,6.97638-.18956l.20982.2a4.93316,4.93316,0,0,1,.18825,6.97572l-19.85846,20.96117A4.92864,4.92864,0,0,1,543.49926,658.00905Z" transform="translate(-224.43432 -165.1988)" fill={theme.accentColor}/>
       </svg>
  );
}
