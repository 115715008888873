// Theme Presets

const lightTheme = {
  name: "light",
  body: "#F6F1E3",
  text: "#343434",
  dark: "#000000",
  secondaryText: "#7F8DAA",
  accentColor: "#6C63FF",
  accentBright: "#6C63FF",
  projectCard: "#DCE4F2",
  skinColor: "#F7B799",
  skinColor2: "#FCB696",
  imageDark: "#dce4f2",
  imageClothes: "#dce4f2",
  avatarMisc: "#e9ecf2",
  avatarShoes: "#ccd2e3",
};
//E3405F
//6C63FF
//5EDCD0

const darkTheme = {
  name: "dark",
  body: "#1D1D1D",
  text: "#FFFFFF",
  dark: "#000000",
  secondaryText: "#8D8D8D",
  accentColor: "#6C63FF",
  accentBright: "#6C63FF",
  projectCard: "#292A2D",
  skinColor: "#F7B799",
  skinColor2: "#FCB696",
  imageDark: "#292A2D",
  imageClothes: "#000000",
  avatarMisc: "#212121",
  avatarShoes: "#2B2B2B",
};

export const themes = { light: lightTheme, dark: darkTheme };
